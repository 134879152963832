import Flutter from "../assets/images/Certifications/flutter.jpg";
import React from "../assets/images/Certifications/react.jpg";
import ReactNative from "../assets/images/Certifications/reactNative.png";
import BootCamp from "../assets/images/Certifications/completeWebBootcamp.jpg";

export const certificates = [
  {
    name: "The Complete 2021 Web Development Bootcamp",
    description:
      "Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps",
    image: BootCamp,
    link: "https://www.udemy.com/course/the-complete-web-development-bootcamp/?utm_source=adwords&utm_medium=udemyads&utm_campaign=WebDevelopment_v.PROF_la.EN_cc.ROW_ti.8322&campaigntype=Search&portfolio=ROW-English&language=EN&product=Course&test=&audience=DSA&topic=&priority=&utm_content=deal4584&utm_term=_._ag_80385735315_._ad_535397279733_._kw__._de_c_._dm__._pl__._ti_dsa-774930035449_._li_1011086_._pd__._&matchtype=&gad_source=1&gclid=Cj0KCQjwltKxBhDMARIsAG8KnqWUQmR6oxTpPzxlbdJgdxIeiqHWtVadamNSFr_4-2X5qSuwqprHc34aAu2JEALw_wcB&couponCode=2021PM25",
  },
  {
    name: "React Native - The Practical Guide [2022]",
    description:
      "A Complete Guide / Tutorial to the Flutter &amp; Dart SDK for building native Android, iOS, Web &amp; Desktop Applications!",
    image: ReactNative,
    link: "https://www.udemy.com/course/react-native-the-practical-guide/?kw=react",
  },
  {
    name: "React - The Complete Guide 2022 (incl. React Router & Redux)",
    description:
      "Dive in and learn React.js from scratch! Learn React, Hooks, Redux, React Router, Next.js, Best Practices and way more!",
    image: React,
    link: "https://www.udemy.com/course/react-the-complete-guide-incl-redux/?kw=react&src=sac",
  },
  {
    name: "The Ultimate Dart & Flutter Course 2023",
    description:
      "A Complete Guide / Tutorial to the Flutter &amp; Dart SDK for building native Android, iOS, Web &amp; Desktop Applications!",
    image: Flutter,
    link: "https://www.udemy.com/course/the-ultimate-dart-flutter-course",
  },
];

export const workPortfolio = [];
