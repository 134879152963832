import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Navbar from "./Components/NavBar/Navbar";
import MainPage from "./Components/MainPage/MainPage";
import "./App.css";

function App() {
  const [selectedSection, setSelectedSection] = useState(null);

  const handleNavbarClick = (section) => {
    setSelectedSection(section);
  };

  return (
    <div className='AppContainer'>
      <Navbar onNavbarClick={handleNavbarClick} />
      <div className='routesContainer'>
        <Routes>
          <Route path='/'>
            <Route
              index
              element={
                <MainPage
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                />
              }
            ></Route>
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
