export const workSamuraiImages = [
  {
    url: require("../../assets/images/WorkSamurai/1.jpeg"),
    caption: "Slide 1",
  },

  {
    url: require("../../assets/images/WorkSamurai/2.jpeg"),
    caption: "Slide 2",
  },
  {
    url: require("../../assets/images/WorkSamurai/3.jpeg"),
    caption: "Slide 3",
  },
  {
    url: require("../../assets/images/WorkSamurai/4.jpeg"),
    caption: "Slide 4",
  },
  {
    url: require("../../assets/images/WorkSamurai/5.jpeg"),
    caption: "Slide 5",
  },
  {
    url: require("../../assets/images/WorkSamurai/6.jpeg"),
    caption: "Slide 6",
  },
  {
    url: require("../../assets/images/WorkSamurai/7.jpeg"),
    caption: "Slide 7",
  },
  {
    url: require("../../assets/images/WorkSamurai/8.jpeg"),
    caption: "Slide 8",
  },
];
export const lmsWebImages = [
  {
    url: require("../../assets/images/LmsWeb/1.png"),
    caption: "Slide 1",
  },

  {
    url: require("../../assets/images/LmsWeb/2.png"),
    caption: "Slide 2",
  },
  {
    url: require("../../assets/images/LmsWeb/3.png"),
    caption: "Slide 3",
  },
  {
    url: require("../../assets/images/LmsWeb/4.png"),
    caption: "Slide 4",
  },
  {
    url: require("../../assets/images/LmsWeb/5.png"),
    caption: "Slide 5",
  },
  {
    url: require("../../assets/images/LmsWeb/6.png"),
    caption: "Slide 6",
  },
  {
    url: require("../../assets/images/LmsWeb/7.png"),
    caption: "Slide 7",
  },
  {
    url: require("../../assets/images/LmsWeb/8.png"),
    caption: "Slide 8",
  },
];
export const lmsMobileImages = [
  {
    url: require("../../assets/images/LmsMobile/1.jpg"),
    caption: "Slide 1",
  },
  {
    url: require("../../assets/images/LmsMobile/2.PNG"),
    caption: "Slide 2",
  },
  {
    url: require("../../assets/images/LmsMobile/3.PNG"),
    caption: "Slide 3",
  },
  {
    url: require("../../assets/images/LmsMobile/4.PNG"),
    caption: "Slide 4",
  },
  {
    url: require("../../assets/images/LmsMobile/5.PNG"),
    caption: "Slide 5",
  },
  {
    url: require("../../assets/images/LmsMobile/6.PNG"),
    caption: "Slide 6",
  },
  {
    url: require("../../assets/images/LmsMobile/7.PNG"),
    caption: "Slide 7",
  },
];
