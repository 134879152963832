import React from "react";
import { Link } from "react-router-dom";

import "./Navbar.css";

function Navbar({ onNavbarClick }) {
  return (
    <>
      <div className='navbar'>
        <Link
          className='removeDecoration'
          to='/'
          onClick={() => onNavbarClick("mainPage")}
        >
          <h3>Hamza's Portfolio</h3>
        </Link>
        <div>
          <Link
            className='removeDecoration'
            to='/'
            onClick={() => onNavbarClick("skills")}
          >
            <p>Skills</p>
          </Link>
          <Link
            className='removeDecoration'
            to='/'
            onClick={() => onNavbarClick("certifications")}
          >
            <p>Certifications</p>
          </Link>
          <Link
            className='removeDecoration'
            to='/'
            onClick={() => onNavbarClick("my-work")}
          >
            <p>My Work</p>
          </Link>
          <Link
            className='removeDecoration'
            to='/'
            onClick={() => onNavbarClick("contact-me")}
          >
            <p className='contact'>Contact Me</p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Navbar;
