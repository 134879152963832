import React from "react";

import "./CertificationTile.css";

function CertificationTile({ name, description, image, link }) {
  return (
    <div
      className='certificationTileContainer'
      // onClick={() => window.open(link)}
    >
      <div className='innerCertificationTileContainer'>
        <img src={image} alt='' />
        <h2>{name}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default CertificationTile;
