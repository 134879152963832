import React, { useEffect } from "react";
import { Slide } from "react-slideshow-image";

import "react-slideshow-image/dist/styles.css";
import "./MainPage.css";
import {
  lmsMobileImages,
  lmsWebImages,
  workSamuraiImages,
} from "./slidingImages";
import Certifications from "../Certifications/Certifications";

function MainPage({ selectedSection, setSelectedSection }) {
  useEffect(() => {
    if (selectedSection) {
      const element = document.getElementById(selectedSection);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      setSelectedSection(null);
    }
  }, [selectedSection, setSelectedSection]);

  return (
    <div className='MainContainer'>
      <div className='subtitle' id='mainPage'>
        <img
          src={require("../../assets/images/Other/user_icon.png")}
          alt='user_icon'
          draggable={false}
        />
        <p>Background & Experience</p>
      </div>
      <div className='bio'>
        <h1>
          Hi there! I'm Hamza, a Full <br />
          Stack developer with 3+ years
          <br />
          of experience in Web and Mobile
          <br />
          applications.
        </h1>
        <div>
          <img
            src={require("../../assets/images/Other/profile_pic4.png")}
            alt='profile pic'
            draggable={false}
          />
          <h3>
            Welcome to my personal portfolio. I have a strong passion for
            <br />
            creating cutting-edge web applications and I'm always eager
            <br /> to take on new challenges. Discover my skills and don't
            <br />
            hesitate to get in touch with me!
          </h3>
        </div>
      </div>
      <hr className='line' />
      <h2 id='skills'>My expertise lie in</h2>
      <div className='skills componentContainer'>
        <div className='divider-div'>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/html-simple.png")}
              alt='html'
              draggable={false}
            />
            <p style={{ color: "#e44d27" }}>HTML</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/css-simple.png")}
              alt='css'
              draggable={false}
            />
            <p style={{ color: "#0277bd" }}>CSS</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/js-simple.png")}
              alt='js'
              draggable={false}
            />
            <p style={{ color: "#d6ba32" }}>Javascript</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/react-logo.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#00ccFF" }}>React.js</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/next-logo.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#000000" }}>Next.js</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/react-native-simpler.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#00d8fe" }}>React Native</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/redux-icon.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#7e57e2" }}>Redux</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/flutter-logo.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#41c4ff" }}>Flutter</p>
          </div>
        </div>
        <div className='divider-div'>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/bootstrap-simple.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#563d76" }}>Bootstrap</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/tailwind-simple.png")}
              alt=''
              className='tailwind-logo'
              draggable={false}
            />
            <p style={{ color: "#06b6d4" }}>Tailwind CSS</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/material-ui-simple.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#027fff" }}>Material UI</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/ant-design-simple.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#f0737f" }}>Ant Design</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/socket-logo.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#000000" }}>Socket.io</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/node-logo.png")}
              alt='node'
              draggable={false}
            />
            <p style={{ color: "#8bc502" }}>Node.js</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/express-simple.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#828282" }}>Express.js</p>
          </div>
          <div className='skills-container'>
            <img
              src={require("../../assets/images/Logos/mongodb-simple.png")}
              alt=''
              draggable={false}
            />
            <p style={{ color: "#508731" }}>MongoDB</p>
          </div>
        </div>
      </div>
      <hr className='line' />
      <h2 id='certifications'>My Certifications</h2>
      <div className='certifications' id='certifications'>
        <Certifications />
      </div>
      {/* <div className='project-details'>
        <div>
          <h2>
            <b>20+</b>
          </h2>
          <p>
            Projects <br /> Completed
          </p>
        </div>
        <div>
          <h2>
            <b>3+</b>
          </h2>
          <p>
            Years Of <br /> Experience
          </p>
        </div>
        <div>
          <h2>
            <b>9+</b>
          </h2>
          <p>
            Skills <br /> Showcased
          </p>
        </div>
        <div>
          <h2>
            <b>15</b>
          </h2>
          <p>
            Satisfied <br /> Clients
          </p>
        </div>
      </div> */}
      <hr className='line' />
      <h2 id='my-work'>My Work</h2>
      <div className='workContainerOuter'>
        <div className='workContainerInner2'>
          <h1 style={{ color: "#cf0f0f" }}>
            OMEGA LMS <b>Web</b>:
          </h1>
          <p>
            Introducing Omega Masonry Lead Management System – a cutting-edge
            solution designed to revolutionize lead management for businesses in
            the fields of mailboxes and real estate.
            <br />
            <br /> Our platform offers a seamless experience, allowing users to
            centralize all their leads in one convenient location. Say goodbye
            to scattered spreadsheets and disparate systems – with Omega
            Masonry, you can effortlessly track, manage, and prioritize every
            potential client.
            <br />
            <br /> One of the standout features of our system is the ability to
            create customizable email and SMS templates. This enables users to
            communicate with their leads in a personalized and impactful way,
            ultimately driving stronger connections and improving conversion
            rates.
          </p>
        </div>
        <div className='workContainerInnerWeb'>
          <div
            style={{
              width: "100%",
              maxHeight: "fit-content",
              overflow: "visible",
            }}
          >
            <Slide canSwipe={true}>
              {lmsWebImages.map((slideImage, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    className='workContainerInnerImgWeb'
                    style={{
                      backgroundImage: `url(${slideImage.url})`,
                    }}
                  ></div> */}
                  <img className='sliderImage' src={slideImage.url} alt='' />
                </div>
              ))}
            </Slide>
          </div>
        </div>
      </div>
      {/* 2 */}
      <hr className='line' />
      <div className='workContainerOuter revert'>
        <div className='workContainerInner1 '>
          <div style={{ width: "100%" }}>
            <Slide canSwipe={true}>
              {workSamuraiImages.map((slideImage, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    className='workContainerInnerImg'
                    style={{
                      // ...divStyle,
                      backgroundImage: `url(${slideImage.url})`,
                      minHeight: "83vh",
                      maxHeight: "83vh",
                    }}
                  ></div> */}
                  <img
                    className='sliderImageMobile'
                    src={slideImage.url}
                    alt=''
                  />
                </div>
              ))}
            </Slide>
          </div>
        </div>
        <div className='workContainerInner2'>
          <h1 style={{ color: "#cf0f0f" }}>
            WorkSamurai <b>Home</b>:
          </h1>
          <p>
            Introducing "WorkSamurai Home" – your ultimate household helper! Say
            goodbye to the stress of chores like cleaning, dishwashing, and
            babysitting. With WorkSamurai Home, you can effortlessly post your
            household tasks and connect with skilled workers through the
            WorkSamurai Worker app.
            <br />
            <br /> Powered by Flutter and utilizing Bloc for state-of-the-art
            management, WorkSamurai Home streamlines your domestic
            responsibilities, making your life easier and more efficient.
            Whether it's tidying up, tackling dishes, or caring for little ones,
            our platform matches you with reliable helpers ready to assist at
            your convenience.
            <br /> <br />
            Experience the convenience of modern home management with
            WorkSamurai Home. Say hello to a cleaner, happier home today!
          </p>
        </div>
      </div>

      {/* 3 */}
      <hr className='line' />
      <div className='workContainerOuter '>
        <div className='workContainerInner2'>
          <h1 style={{ color: "#cf0f0f" }}>
            OMEGA LMS <b>Mobile</b>:
          </h1>
          <p>
            Introducing Omega Masonry Lead Management System – a cutting-edge
            solution designed to revolutionize lead management for businesses in
            the fields of mailboxes and real estate.
            <br />
            <br /> Our platform offers a seamless experience, allowing users to
            centralize all their leads in one convenient location. Say goodbye
            to scattered spreadsheets and disparate systems – with Omega
            Masonry, you can effortlessly track, manage, and prioritize every
            potential client.
            <br />
            <br /> One of the standout features of our system is the ability to
            create customizable email and SMS templates. This enables users to
            communicate with their leads in a personalized and impactful way,
            ultimately driving stronger connections and improving conversion
            rates.
          </p>
        </div>
        <div className='workContainerInner1'>
          <div style={{ width: "100%" }}>
            <Slide canSwipe={true}>
              {lmsMobileImages.map((slideImage, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    className='workContainerInnerImg'
                    style={{
                      // ...divStyle,
                      backgroundImage: `url(${slideImage.url})`,
                      minHeight: "83vh",
                      maxHeight: "83vh",
                    }}
                  ></div> */}
                  <img
                    className='sliderImageMobile'
                    src={slideImage.url}
                    alt=''
                  />
                </div>
              ))}
            </Slide>
          </div>
        </div>
      </div>
      {/* Contact Me */}
      <hr className='line' />
      <h2 id='contact-me' style={{ color: "#ff5168" }}>
        Contact Me
      </h2>
      <div className='contactContainer'>
        <div className='contactImageLogo'>
          <img
            src={require("../../assets/images/Other/contact-me.png")}
            alt=''
          />
        </div>
        <div className='contactIconsContainer'>
          <div className='contactIconsInnerContainer'>
            <p>
              Interested in working with me? You can
              <br /> reach me at:
            </p>
            <div className='contactIcons'>
              <img
                style={{ minHeight: "4.3vh", maxHeight: "4.3vh" }}
                src={require("../../assets/images/Logos/gmail-logo.png")}
                onClick={() =>
                  (window.location = "mailto:hamzarasheed4718@gmail.com")
                }
                alt=''
              />
              <img
                src={require("../../assets/images/Logos/whatsapp3.png")}
                alt=''
                onClick={() => window.open("https://wa.me/923315799227")}
              />
              <img
                src={require("../../assets/images/Logos/linkedin-logo.png")}
                alt=''
                onClick={() =>
                  window.open("https://www.linkedin.com/in/itsmhr/")
                }
              />
              <img
                src={require("../../assets/images/Logos/indeed-logo.png")}
                alt=''
                onClick={() =>
                  window.open(
                    "https://profile.indeed.com/?hl=en_PK&co=PK&from=gnav-homepage&_ga=2.268914481.1764227191.1714502207-707291129.1709989953"
                  )
                }
              />
              <img
                src={require("../../assets/images/Logos/upwork-logo.png")}
                alt=''
                onClick={() =>
                  window.open(
                    "https://www.upwork.com/freelancers/~012bce0e1cc5154757?mp_source=share"
                  )
                }
              />
              <img
                src={require("../../assets/images/Logos/fiverr-logo.png")}
                alt=''
                onClick={() =>
                  window.open("https://www.fiverr.com/mhrnotfound")
                }
              />
              {/* <img
              src={require("../../assets/images/facebook-logo.png")}
              alt=''
              />
              <img
              src={require("../../assets/images/instagram-logo.png")}
              alt=''
            />*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
