import React from "react";

import CertificationTile from "./CertificationTile/CertificationTile";
import { certificates } from "../jsons";
import "./Certifications.css";

function Certifications() {
  return (
    <div className='certificatesMapContainer'>
      {certificates.map((certificate) => (
        <div className='certificatesMapItem'>
          <CertificationTile
            name={certificate.name}
            description={certificate.description}
            image={certificate.image}
            link={certificate.link}
          />
        </div>
      ))}
    </div>
  );
}

export default Certifications;
